<template>
  <div class="appointment-booker__container">
    <iframe :src="url" frameborder="0" class="iframe" v-show="!completed"></iframe>
    <div v-if="completed" class="completed__container d-flex justify-center align-center">
      <div class="completed__container--inner">
        <div class="completed__container--header mb-1">
          <v-icon size="3em" color="primary" class="calendar-icon">mdi-calendar-check</v-icon>
          <h3>Appointment booked!</h3>
        </div>
        <div class="d-flex flex-column no-wrap justify-center align-center">
          <!-- <v-btn rounded color="primary">Book another appointment</v-btn> -->
          <v-btn rounded color="primary" class="mb-0" text @click="repeat">
            Book another appointment</v-btn
          >
          <v-btn rounded class="mt-12" @click="goHome"> Back to home</v-btn>
          <!-- <v-btn rounded>Go back</v-btn> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.appointment-booker__container {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  overflow: hidden;
  right: 0;
  bottom: 0;
  overscroll-behavior: contain;
}
.iframe {
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  width: 100%;
}

.completed__container {
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>
<script>
/* eslint-disable no-underscore-dangle */
export default {
  name: 'CannabisAppointmentBooker',
  data() {
    return {
      completed: false,
    };
  },
  computed: {
    url() {
      const { dateOfBirth, email, firstName, lastName, gender, phone, healthCard } =
        this.$store.getters['patientPortal/currentUser'];
      const dobFormatted = this.$store.getters.toUTC(dateOfBirth).format('YYYY-MM-DD');

      delete healthCard.__typename;
      delete phone.__typename;

      const str = Object.entries({
        dateOfBirth: dobFormatted,
        email,
        firstName,
        lastName,
        gender,
        phone,
        healthCard,
      })
        .reduce((all, [key, value]) => {
          if (typeof value === 'object') {
            return [...all, `${key}=${JSON.stringify(value)}`];
          }
          return [...all, `${key}=${value}`];
        }, [])
        .join('|');
      const formatted = str
        .split('')
        .map((char) => {
          const code = char.charCodeAt(0);
          return `%${code}`;
        })
        .join('');

      const { host } = window.location;
      const tenantUrl = localStorage.getItem('tenantUrl');
      const url = `http${
        window.location.hostname === 'localhost' ? '' : 's'
      }://${host}/register/${tenantUrl}/cannabis?pi=${formatted}&hideHeader=true&embed=true&noIntro=true`;
      const cleaned = url.replaceAll(' ', '%20').replaceAll('"', '%22');
      return cleaned;
    },
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'Home' });
    },
    onRegistrationEnd() {
      this.completed = true;
    },
    repeat() {
      this.completed = false;
    },
  },
  mounted() {
    window.document.addEventListener('registrationEnd', this.onRegistrationEnd);
  },
  beforeDestroy() {
    window.document.removeEventListener('registrationEnd', this.onRegistrationEnd);
  },
};
</script>
